import { ResourceKey } from "i18next";

export const Settings: ResourceKey = {
	settings: "Settings",
	account: "Account",
	password: "Password",
	company: "Company",
	invoice: "Invoicing",
	labels: "Labels",
	email: "Email",
	profiles: "Profiles",
	countries: "Countries",
	no_countries_left_message_start: "Your plan only allows for",
	no_countries_left_message_end_singular: "country. To change your plan, go to the 'Price Plan' tab.",
	no_countries_left_message_end_plural: "countries. To change your plan, go to the 'Price Plan' tab.",
	plan: "Price Plan",
	feedback: "Feedback",
	save: "Save",
	saving: "Saving...",
	saved: "Saved",
	totalPrice: "Total price",
	street: "Street",
	number: "Number",
	zip: "Zip code",
	town: "Town",
	phone: "Phone number",
	current_password: "Current password",
	new_password: "New password",
	confirm_new_password: "Confirm new password",
	add_or_remove_from_account: "Add or remove email addresses on your account",
	manage_email_addresses: "Manage addressees",
	email_address: "email address",
	email_addresses: "email addresses",
	add_email_address: "Add email address",
	primary: "Primary",
	make_primary: "Make primary",
	primary_email_address_taken_error: "This email address is taken",
	daily_mail_frequency: "I want to be alerted by e-mail when a new selection is available",
    daily_mail_add_awards: "I also want to receive new awards",
	add_label: "Add label",
	add_label_group: "Add label group",
	add_label_group_name: "Name",
	add_label_group_color: "Colour",
	rename_label: "Rename",
	rename_label_popup_title: "Rename label",
	rename_label_popup_button: "Rename",
	move_label: "Move",
	move_label_popup_title: "Move label",
	move_label_popup_button: "Move",
	remove_label_from_group: "Remove from group",
	delete_label: "Delete",
	delete_label_confirm: "Permanently remove label?",
	edit_label_group: "Edit",
	delete_label_group: "Delete",
	delete_label_group_confirm: "Permanently remove label group?",
	undo_label_changes: "Undo changes",
	label_must_have_a_name: "Label must have a name",
	label_group_must_have_a_name: "Label group must have a name",
	label_with_name_already_exists_first_part: "Label with name '",
	label_with_name_already_exists_second_part: "' already exists",
	label_group_with_name_already_exists_first_part: "Label group with name '",
	label_group_with_name_already_exists_second_part: "' already exists",
	you_have_reached_your_label_limit: "Your current price plan does not allow you to add more labels. Choose a higher price plan or contact a TenderWolf representative.",
    invalid_label:"Invalid label",
	masquerade: "Masquerade",
	logout: "Logout",
	send_feedback: "Send",
	sending_feedback: "Send...",
	sent_feedback: "Feedback sent",
	invalid_email_address: "Invalid email address",
	feedback_your_input_is_invaluable: "Your input is invaluable",
	feedback_we_are_constantly_evolving: "Tenderwolf is continuously evolving. The team behind TenderWolf is constantly looking for ways to make TenderWolf even faster, more precise and more user-friendly, and to add extra functions. Your feedback is invaluable here. So don't hesitate, and contact us if you think something could be improved or if you miss a specific functionality.",
	feedback_fill_out_the_form: "Fill out the form below and we will immediately get to work with your input.",
	feedback_thank_you: "Thank you in advance!",
	feedback_name_placeholder: "Name",
	feedback_company_placeholder: "Company",
	feedback_mail_placeholder: "Email address",
	feedback_message_placeholder: "Message",
	search: "Search",
	unsavedChangesMessage: "You have unsaved changes. The changes will be lost. Are you sure you want to continue?",
	unsavedChangesTitle: "Unsaved changes",
	createNewProfile: "Create new profile",
	create: "Create",
	editProfileTitle: "Edit profile",
	deleteProfileTitle: "Delete profile",
	deleteProfileMessage: "Do you want to delete this profile?",
    name_this_profile: "Name this profile",
    name_this_profile_for_example: "Name this profile, for example: ",
    profile_name_placeholder: "Profile name",
	delete: "Delete",
	until: "Until",
	current_plan: "Your current plan",
	change_plan: "Change your plan",
	back: "Back",
	change_plan_prompt: "Do you want to change your plan?",
	click_here: "Click here",
	plan_information: "Information",
	plan_frequency: "Frequency",
	plan_renewal_date: "Renewal date",
	your_trial_plan_has_been_renewed: "Your plan has been changed.",
	your_trial_plan_has_not_been_renewed: "You have exceeded the maximum amount of trial periods.",
	next_plan_name: "Plan",
	plan_frequency_monthly: "Monthly",
	plan_frequency_yearly: "Yearly",
	choose: "Choose",
	plan_change_message_part_one: "You want to switch to the price plan ",
	plan_change_message_part_two_upgrade: "Upon confirmation, this happens immediately.",
	plan_change_message_part_two_downgrade: "Upon confirmation this happens on ",
    plan_change_message_downgrade_disclaimer: "Please note that your new plan has fewer features. As a result, several of your data may be removed.",
	plan_change_success: "Your plan has been successfully changed.",
    future_plan_change_success: "Your next plan has been changed.",
	no_invoice_address_title: "Missing VAT number",
	no_invoice_address_message: "To purchase a new plan we need your VAT number. Please fill in your VAT number in the 'Invoicing' tab.",
	help: "Help",

}