import {
  SearchFilterState,
  TenderSearch,
  TenderSearchInput,
  TenderSearchMetadata,
  TenderSearchState,
} from "../hooks/slices/tenderSearchSlice";
import {Country} from "../consts/countries";
import { SearchFilterUtil } from "./searchFilters";
import {LanguageParser} from "../consts/languages";
import {SearchType} from "../consts/searchType";
import {SavedSearchUtil} from "./savedsearches";
import {TenderCardConfiguration} from "../pages/search/results/body/TenderCard";
import {SearchConfiguration} from "../pages/search/GenericSearchPage";
import {FilterGroup} from "../consts/FilterGroup";
import {SortBy} from "../consts/SortBy";
import {CodeBranch, CodeWithClass} from "../types/code";
import {TenderSearchesState} from "../hooks/slices/savedSearchesSlice";
import {SearchPhase} from "../consts/searchPhase";
import {SearchFilter} from "../consts/SearchFilter";
import {TFunction} from "react-i18next";

export namespace SearchUtil {

  export function getSearchParameters(
    tenderSearchInStore: TenderSearchState,
    addAggregations: boolean,
    searchConfiguration: SearchConfiguration,
    availableCountries: Country[],
    savedSearches: TenderSearchesState,
    searchPhase: SearchPhase, t: TFunction, language: string
  ): TenderSearchInput {
    // let cues = SavedSearchUtil.getAllCues(savedSearches);
    let mainInput: TenderSearch = getMainTenderSearchInput(
      tenderSearchInStore.searchFilters,
      addAggregations,
      searchConfiguration,
      availableCountries
    );
    let filterInputs: TenderSearch[] = [];
    let savedSearchNames: string[] = tenderSearchInStore.searchFilters.savedSearches ? tenderSearchInStore.searchFilters.savedSearches : [];
    if (savedSearchNames.length > 0) {
      let filterGroups: FilterGroup[] = [];
      if (tenderSearchInStore.searchType === SearchType.OPPORTUNITIES) filterGroups.push(FilterGroup.OPPORTUNITY);
      if (tenderSearchInStore.searchType === SearchType.AWARDS) filterGroups.push(FilterGroup.AWARD);
      SavedSearchUtil.getSelectedTenderSearches(savedSearches, savedSearchNames, t, language)
          .forEach((p) => filterInputs.push(getSearchFilterInput(p, filterGroups)));
    }
    return {
      metadata: getTenderSearchMetadata(tenderSearchInStore, addAggregations, searchConfiguration, searchPhase),
      mainInput: mainInput,
      filterInputs: filterInputs,
    };
  }

  function notUndefinedOrEmpty<T>(array: T[] | undefined): boolean {
    return array !== undefined && array.length > 0;
  }

  export function getTenderSearchMetadata(
      tenderSearchState: TenderSearchState,
      addAggregations: boolean,
      searchConfiguration: SearchConfiguration,
      searchPhase: SearchPhase
  ): TenderSearchMetadata{
    let cardConfiguration = searchConfiguration.tenderCard;
    let searchFilterState = tenderSearchState.searchFilters
    let queryIsNotEmpty = tenderSearchState.searchFilters.query != null && tenderSearchState.searchFilters.query.length > 0;
    let addQueryHighlights = queryIsNotEmpty &&
        (cardConfiguration.showAddendumHighlights || cardConfiguration.showQueryHighlights);
    let addCueHighlightsForTenderCard = cardConfiguration.showCueHighlights && queryIsNotEmpty;
    let addCueHighlightsForHeader = searchConfiguration.info.showCueAggregations;
    let addCueHighlights = addCueHighlightsForTenderCard || addCueHighlightsForHeader;
    return {
      searchType: tenderSearchState.searchType ? tenderSearchState.searchType : SearchType.SEARCH,
      searchPhase: searchPhase,
      addAggregations: addAggregations,
      addMainQueryHighlights: addQueryHighlights,
      addFilterQueryHighlights: addCueHighlights,
      fieldsToFetch: getFieldsNeededForCard(cardConfiguration),
      highlightSize: 100,
      maxHighlightedFilesPerTender: 10,
      maxHighlights: 3,
      page: searchPhase === SearchPhase.HIGHLIGHTS ? 0 : searchFilterState.page,
      pageSize: searchFilterState.pageSize,
      sortBy: getSortBy(searchFilterState, tenderSearchState.searchType === SearchType.OPPORTUNITIES),
      labelFilters: searchFilterState.labelFilters,
      tenderUuidFilters: searchFilterState.tenderUuidFilters,
      tenderUuidsToIgnore: searchFilterState.tenderUuidsToIgnore,
      vatNumberFilters: searchFilterState.vatNumberFilters,
      sortBySimilarityTo: searchFilterState.similarTender ? [searchFilterState.similarTender.uuid] : undefined,
    }
  }

  export function getMainTenderSearchInput(
    searchFilterState: SearchFilterState,
    addAggregations: boolean,
    searchConfiguration: SearchConfiguration,
    availableCountries: Country[]
  ): TenderSearch {

    let accreditations: CodeWithClass[] = searchFilterState.accreditations ? searchFilterState.accreditations : [];
    let subjectCodes: CodeBranch[] = searchFilterState.subjectCodes ? searchFilterState.subjectCodes : [];
    let regionCodes: CodeBranch[] = searchFilterState.regionCodes ? searchFilterState.regionCodes: [];
    return {
      name: "main",
      accreditations: accreditations,
      containsFields: SearchFilterUtil.translateContainsFieldsFilters(searchFilterState),
      contractTypes: SearchFilterUtil.translateContractTypeFilters(searchFilterState),
      contractingAuthorityTypes: SearchFilterUtil.translateContractingAuthorityTypeFilters(searchFilterState),
      countries: translateCountries(searchFilterState, availableCountries),
      deadlineStartDate: searchFilterState.startDeadlineDate,
      deadlineEndDate: searchFilterState.endDeadlineDate,
      doesNotContainFields: SearchFilterUtil.translateDoesNotContainFieldsFilters(searchFilterState),
      fieldGroups: SearchFilterUtil.translateFieldGroupFilters(searchFilterState),
      languageIsos: translateLanguages(searchFilterState),
      procedureTypes: SearchFilterUtil.translateProcedureTypeFilters(searchFilterState),
      publicationStartDate: searchFilterState.startPublicationDate,
      publicationEndDate: searchFilterState.endPublicationDate,
      awardOrOpeningReportPublicationStartDate: searchFilterState.awardOrOpeningReportPublicationStartDate,
      awardOrOpeningReportPublicationEndDate: searchFilterState.awardOrOpeningReportPublicationEndDate,
      publicationRadius: SearchFilterUtil.translatePublicationRadiusFilters(searchFilterState),
      regionCodes: regionCodes,
      query: searchFilterState.query,
      subjectCodes: subjectCodes,
      isFrameworkAgreement: getOptionalBoolean(searchFilterState, SearchFilter.IS_FRAMEWORK_AGREEMENT_YES, SearchFilter.IS_FRAMEWORK_AGREEMENT_NO),
      isShelteredWorkshop: getOptionalBoolean(searchFilterState, SearchFilter.IS_SHELTERED_WORKSHOP, null),
      isShelteredProgram: getOptionalBoolean(searchFilterState, SearchFilter.IS_SHELTERED_PROGRAM, null),
    };
  }

  function getOptionalBoolean(SearchFilterState : SearchFilterState, yes : SearchFilter, no: SearchFilter | null) : boolean | undefined {
    if(SearchFilterState.selectedFilters.includes(yes)) return true;
    if(no != null && SearchFilterState.selectedFilters.includes(no)) return false;
    return undefined;
  }

  function getSortBy(searchFilterState: SearchFilterState, opportunitySearch: boolean): string {
    if (
      searchFilterState.sortBy === SortBy.RELEVANCE &&
      searchFilterState.query === "" &&
      !opportunitySearch
    ) {
      return searchFilterState.publicationDateToSortBy.valueOf();
    } else if (searchFilterState.sortBy === SortBy.RELEVANCE) {
      return "RELEVANCE";
    } else if (searchFilterState.sortBy === SortBy.DEADLINE) {
      return "DEADLINE_DATE_DESC";
    } else {
      return searchFilterState.publicationDateToSortBy.valueOf();
    }
  }


  export function getSearchFilterInput(savedSearch: TenderSearch, filterGroups: FilterGroup[]): TenderSearch {
    return {
      name: savedSearch.name,
      accreditations: savedSearch.accreditations,
      containsFields: savedSearch.containsFields,
      contractTypes: savedSearch.contractTypes,
      contractingAuthorityTypes: savedSearch.contractingAuthorityTypes,
      countries: savedSearch.countries,
      deadlineStartDate: savedSearch.deadlineStartDate,
      deadlineEndDate: savedSearch.deadlineEndDate,
      doesNotContainFields: savedSearch.doesNotContainFields,
      fieldGroups: savedSearch.fieldGroups,
      filterGroups: filterGroups,
      languageIsos: savedSearch.languageIsos,
      procedureTypes: savedSearch.procedureTypes,
      publicationStartDate: savedSearch.publicationStartDate,
      publicationEndDate: savedSearch.publicationEndDate,
      awardOrOpeningReportPublicationStartDate: savedSearch.awardOrOpeningReportPublicationStartDate,
      awardOrOpeningReportPublicationEndDate: savedSearch.awardOrOpeningReportPublicationEndDate,
      publicationRadius: savedSearch.publicationRadius,
      query: savedSearch.query,
      regionCodes: savedSearch.regionCodes,
      subjectCodes: savedSearch.subjectCodes,
      isFrameworkAgreement: savedSearch.isFrameworkAgreement,
      isShelteredWorkshop: savedSearch.isShelteredWorkshop,
      isShelteredProgram: savedSearch.isShelteredProgram,
    }
  }

  export function getFieldsNeededForCard(cardConfiguration: TenderCardConfiguration): string[] | undefined {
    let fieldsToFetch = [
      "contractingAuthority",
      "publicationRadius",
      "publishers",
      "title",
      "description",
      "publicationDate",
      "deadline",
    ];
    //these are needed because of the conversion in nighteyes between TenderDocumentV3 and TenderDocument, which
    //throws nullpointer exceptions otherwise
    fieldsToFetch.push("awardModifications.publicationInformation");
    fieldsToFetch.push("corrigendumChanges.publicationInformation");
    fieldsToFetch.push("contractAwards.publicationInformation");
    fieldsToFetch.push("openingReportBids.publicationInformation");
    fieldsToFetch.push("designContests.publicationInformation");
    fieldsToFetch.push("contractNoticeLots.publicationInformation");
    fieldsToFetch.push("priorInformationNoticeLots.publicationInformation");
    fieldsToFetch.push("municipalDecisions.publicationInformation");
    //..
    if (cardConfiguration.showCompanies) {
      fieldsToFetch.push("contractAwards");
      fieldsToFetch.push("openingReportBids");
    }
    if (cardConfiguration.showPublicationDetails) {
      fieldsToFetch.push("contractNoticeLots.estimatedValue");
      fieldsToFetch.push("priorInformationNoticeLots.estimatedValue");
      fieldsToFetch.push("regionCodes");
      fieldsToFetch.push("mainSubjectCodes");
      fieldsToFetch.push("additionalSubjectCodes");
      fieldsToFetch.push("accreditations");
    }
    if (cardConfiguration.showProgress) {
      fieldsToFetch.push("awardModifications.publicationInformation.publicationDate");
      fieldsToFetch.push("corrigendumChanges.publicationInformation.publicationDate");
      fieldsToFetch.push("contractAwards.publicationInformation.publicationDate");
      fieldsToFetch.push("openingReportBids.publicationInformation.publicationDate");
      fieldsToFetch.push("designContests.publicationInformation.publicationDate");
      fieldsToFetch.push("contractNoticeLots.publicationInformation.publicationDate");
      fieldsToFetch.push("priorInformationNoticeLots.publicationInformation.publicationDate");
      fieldsToFetch.push("municipalDecisions.publicationInformation.publicationDate");
    }
    if (cardConfiguration.showAddendumHighlights) {
      fieldsToFetch.push("addenda.file");
    }
    if (cardConfiguration.showAwardDateInsteadOfDeadline) {
      fieldsToFetch.push("awardDate");
    }
    if(cardConfiguration.showLatestPublicationTypeInUpperRightCorner){
      fieldsToFetch.push("municipalDecisions.publicationInformation.publicationDate");
    }
    return fieldsToFetch;
  }

  export function translateCountries(searchFilters: SearchFilterState, availableCountries: Country[]): string[] {
    if (searchFilters.searchShortlist) {
      //countries are ignored in the context of the shortlist
      return [];
    }
    let countryFilters: Country[] = SearchFilterUtil.getCountriesFromCountryFilters(searchFilters.selectedFilters);
    if (countryFilters.length > 0) return countryFilters;
    else return availableCountries;
  }

  export function translateLanguages(searchFilters: SearchFilterState): string[] {
    let languages: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f.startsWith("LANGUAGE_")) languages.push(LanguageParser.fromString(f.replace("LANGUAGE_", "")));
    }
    // if (languages.length === 0) return [Language.NL]
    return languages;
  }
}