import { ResourceKey } from "i18next";

export const Settings: ResourceKey = {
	settings: "Instellingen",
	account: "Account",
	password: "Wachtwoord",
	company: "Bedrijf",
	invoice: "Facturatie",
	labels: "Labels",
	email: "E-mail",
	profiles: "Profielen",
	countries: "Landen",
	no_countries_left_message_start: "Je plan laat maar",
	no_countries_left_message_end_singular: "land toe. Ga naar het tabblad 'Prijsplan' om je plan te veranderen.",
	no_countries_left_message_end_plural: "landen toe. Ga naar het tabblad 'Prijsplan' om je plan te veranderen.",
	plan: "Prijsplan",
	feedback: "Feedback",
	save: "Opslaan",
	saving: "Opslaan...",
	saved: "Opgeslagen",
	totalPrice: "Totale prijs",
	street: "Straat",
	number: "Nummer",
	zip: "Postcode",
	town: "Gemeente",
	phone: "Telefoonnummer",
	current_password: "Huidig wachtwoord",
	new_password: "Nieuw wachtwoord",
	confirm_new_password: "Bevestig nieuw wachtwoord",
	add_or_remove_from_account: "Voeg e-mailadressen toe of verwijder ze van je account",
	manage_email_addresses: "Beheer je e-mailadressen",
	email_address: "e-mailadres",
	email_addresses: "e-mailadressen",
	add_email_address: "Voeg e-mailadres toe",
	primary: "Primair",
	make_primary: "Maak primair",
	primary_email_address_taken_error: "Dit e-mailadres is in gebruik",
	daily_mail_frequency: "Ik wil via e-mail gewaarschuwd worden bij een nieuwe selectie",
    daily_mail_add_awards: "Ik wil ook nieuwe gunningen ontvangen",
	add_label: "Label toevoegen",
	add_label_group: "Labelgroep toevoegen",
	add_label_group_name: "Naam",
	add_label_group_color: "Kleur",
	rename_label: "Hernoemen",
	rename_label_popup_title: "Hernoem label",
	rename_label_popup_button: "Hernoem",
	move_label: "Verplaatsen",
	move_label_popup_title: "Verplaats label",
	move_label_popup_button: "Verplaats",
	remove_label_from_group: "Loskoppelen van groep",
	delete_label: "Verwijderen",
	delete_label_confirm: "Label permanent verwijderen?",
	edit_label_group: "Aanpassen...",
	delete_label_group: "Verwijderen",
	delete_label_group_confirm: "Labelgroep permanent verwijderen?",
	undo_label_changes: "Wijzigingen ongedaan maken",
	label_must_have_a_name: "Label moet een naam hebben",
	label_category_must_have_a_name: "Labelgroep moet een naam hebben",
	label_with_name_already_exists_first_part: "Label met naam '",
	label_with_name_already_exists_second_part: "' bestaat al",
	label_group_with_name_already_exists_first_part: "Label groep met naam '",
	label_group_with_name_already_exists_second_part: "' bestaat al",
	you_have_reached_your_label_limit: "Je huidig prijsplan laat niet toe om nog meer labels toe te voegen. Kies een hoger prijsplan of neem contact op met een TenderWolf-medewerker.",
    invalid_label:"Label ongeldig",
	masquerade: "Masquerade",
	logout: "Log uit",
	send_feedback: "Verstuur",
	sending_feedback: "Verstuur...",
	sent_feedback: "Verstuurd",
	invalid_email_address: "Ongeldig e-mailadres",
	feedback_your_input_is_invaluable: "Uw inbreng is van onschatbare waarde",
	feedback_we_are_constantly_evolving: "Tenderwolf is voortdurend in ontwikkeling. Het team achter TenderWolf is voortdurend op zoek naar manieren om TenderWolf nog sneller, nauwkeuriger en gebruiksvriendelijker te maken, en om extra functies toe te voegen. Uw feedback is daarbij van onschatbare waarde. Aarzel dus niet en neem contact met ons op als u denkt dat er iets verbeterd kan worden of als u een bepaalde functionaliteit mist.",
	feedback_fill_out_the_form: "Vul het onderstaande formulier in en we gaan meteen aan de slag met je input.",
	feedback_thank_you: "Alvast bedankt!",
	feedback_name_placeholder: "Naam",
	feedback_company_placeholder: "Bedrijf",
	feedback_mail_placeholder: "E-mailadres",
	feedback_message_placeholder: "Boodschap",
	search: "Zoeken",
	unsavedChangesMessage: "Je wijzigingen zijn nog niet opgeslagen. Ben je zeker dat je door wil gaan? De aangebrachte wijzigingen zullen verloren gaan.",
	unsavedChangesTitle: "Onopgeslagen wijzigingen",
	createNewProfile: "Nieuw profiel aanmaken",
	deleteProfileMessage: "Wil je dit profiel verwijderen?",
	deleteProfileTitle: "Profiel verwijderen",
	editProfileTitle: "Profiel aanpassen",
	name_this_profile: "Geef dit profiel een naam",
    name_this_profile_for_example: "Geef dit profiel een naam, bijvoorbeeld: ",
    profile_name_placeholder: "Profielnaam",
	create: "Aanmaken",
	delete: "Verwijder",
	until: "Nog tot",
	current_plan: "Je huidig prijsplan",
	change_plan: "Wijzig je prijsplan",
	back: "Terug",
	change_plan_prompt: "Wil je jouw prijsplan wijzigen?",
	click_here: "Klik hier",
	plan_information: "Informatie",
	plan_frequency: "Frequentie",
	plan_renewal_date: "Wordt vernieuwd op",
	your_trial_plan_has_been_renewed: "Je plan is met succes gewijzigd.",
	your_trial_plan_has_not_been_renewed: "Je hebt het maximum aantal trial periodes overschreden.",
	next_plan_name: "Plan",
	plan_frequency_monthly: "Maandelijks",
	plan_frequency_yearly: "Jaarlijks",
	choose: "Kies",
	plan_change_message_part_one: "Je wil overschakelen naar het prijsplan ",
	plan_change_message_part_two_upgrade: "Bij bevestiging gebeurt dit onmiddellijk.",
	plan_change_message_part_two_downgrade: "Bij bevestiging gebeurt dit op ",
    plan_change_message_downgrade_disclaimer: "Opgelet: je nieuwe plan heeft minder features. Hierdoor kunnen verschillende van je gegevens verwijderd worden.",
	plan_change_success: "Je plan is met succes gewijzigd.",
    future_plan_change_success: "Je volgende plan is met succes gewijzigd.",
	no_invoice_address_title: "Ontbrekend BTW-nummer",
	no_invoice_address_message: "Voor de aankoop van een nieuw plan hebben we je BTW-nummer nodig. Vul je BTW-nummer in op het tabblad 'Facturatie'.",
	help: "Help",
}