import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { resetVatNumberFilters, updateSearchFilters } from "../../../../hooks/slices/tenderSearchSlice";
import { RaiseCurrentPlan } from "../../../../hooks/raiseCurrentPlan";
import { FeatureUtil } from "../../../../utils/features";
import { Feature, FeatureName } from "../../../../consts/features";
import { RaiseUser } from "../../../../hooks/raiseUser";
import {UserInteraction} from "../../../../hooks/slices/userSlice";

enum AwardsCategory {
  SHORTLIST,
  COMPANIES,
  SECTOR,
  EVERYTHING,
}

export const AwardsCategories = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
  const searchFilters = useAppSelector((state) => state.tenderSearch.searchFilters);
  const savedSearches = useAppSelector((state) => state.savedSearches.tenderSearches);
  const curatedSearches = useAppSelector((state) => state.savedSearches.curatedTenderSearches);
  let savedSearchNames = savedSearches?.map((s) => s.name) ?? [];
  curatedSearches?.forEach((c) => savedSearchNames.push(c.dutchName));
  const followedCompanies = RaiseUser().user?.companyPageInteractions ?? [];
  const getFollowedCompanyVatNumbers = () => {
    let vatNumbers = followedCompanies.map((c) => c.companyPageVatNumber);
    if(vatNumbers.length === 0) vatNumbers.push("no_followed_companies");
    return vatNumbers;
  }
  const initialFilter = searchFilters.savedSearches ? AwardsCategory.SECTOR : searchFilters.searchShortlist ? AwardsCategory.SHORTLIST : AwardsCategory.EVERYTHING;
  const [filterEnabled, setFilterEnabled] = React.useState<AwardsCategory>(initialFilter);
  const toggleSearchSectorAwards = () => {
    setFilterEnabled(AwardsCategory.SECTOR);
    dispatch(updateSearchFilters({ savedSearches: savedSearchNames, searchShortlist: false, userInteractions: userInteractions }));
    dispatch(resetVatNumberFilters())
  };
  const toggleSearchShortlist = () => {
    setFilterEnabled(AwardsCategory.SHORTLIST);
    dispatch(updateSearchFilters({ savedSearches: [], searchShortlist: true, userInteractions: userInteractions }));
    dispatch(resetVatNumberFilters())
  };
  const toggleSearchEverything = () => {
    setFilterEnabled(AwardsCategory.EVERYTHING);
    dispatch(updateSearchFilters({ savedSearches: [], searchShortlist: false, userInteractions: userInteractions }));
    dispatch(resetVatNumberFilters())
  };
  const toggleSearchTendersParticipatedInByFollowedCompanies = () => {
    setFilterEnabled(AwardsCategory.COMPANIES);
    dispatch(updateSearchFilters({ savedSearches: [], searchShortlist: false, userInteractions: userInteractions }));
    dispatch(updateSearchFilters({vatNumberFilters: getFollowedCompanyVatNumbers(), userInteractions: userInteractions}))
  };

  const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  let opportunitiesLimit = FeatureUtil.getFeatureLimit(features, FeatureName.UPDATE_MAIL_OPPORTUNITIES);
  return (
    <>
      <div className="leads-align">
        <span onClick={toggleSearchShortlist} className={filterEnabled === AwardsCategory.SHORTLIST ? "selected" : ""}>
          {t("publications.searchLeads")}
        </span>
        {!opportunitiesLimit && (
          <>
            <span>|</span>
            <span
              className={filterEnabled === AwardsCategory.COMPANIES ? "selected" : ""}
              onClick={toggleSearchTendersParticipatedInByFollowedCompanies}>
              {t("publications.searchCompanies")}
            </span>
            <span>|</span>
            <span onClick={toggleSearchSectorAwards} className={filterEnabled === AwardsCategory.SECTOR ? "selected" : ""}>
              {t("publications.searchSector")}
            </span>
            <span>|</span>
            <span onClick={toggleSearchEverything} className={filterEnabled === AwardsCategory.EVERYTHING ? "selected" : ""}>
              {t("publications.searchEverything")}
            </span>
          </>
        )}
      </div>
    </>
  );
};


