import { ResourceKey } from "i18next";

export const SavedSearchSettings: ResourceKey = {
	generalSettings: "Algemeen",
	settings: "Instellingen",
	keyWords: "Zoektermen",
	subjectCodes: "Opdrachtcodes (CPV)",
	regionCodes: "Regiocodes (NUTS)",
	accreditations: "Erkenningen",
	formTypes: "Formuliertypes",
	save: "Opslaan",
	accountSettings: "Account",
	newKeyword: "Nieuwe zoekterm",
  duplicateKeywords: "Trefwoorden bevatten dubbels",
  illegalQuotesInKeywords: "Trefwoorden bevatten gekrulde aanhalingstekens, vervang ze door rechte aanhalingstekens (', \")",
	addProfile: "Profiel toevoegen",
	name: "Naam van het profiel",
  invalidProfileName: "Ongeldige profielnaam",
	defaultName: "Selectieprofiel",
	SavedSearchFilterButtonTitle: "Profiel",
	confirmRemove: "Ben je zeker dat je dit selectieprofiel wil verwijderen?",
	expertManaged: "Dit profiel wordt door TenderWolf onderhouden. Contacteer onze Tender Search Expert voor meer flexibiliteit.",
	noResults: "Geen resultaten",
};
