import React from "react";
import {CloseIcon} from "../../../../components/icons";
import {useTranslation} from "react-i18next";
import {RaiseUser} from "../../../../hooks/raiseUser";
import {CompanyPageInteraction, UserInteraction} from "../../../../hooks/slices/userSlice";
import {useAppDispatch} from "../../../../app/hooks";
import {updateSearchFilters} from "../../../../hooks/slices/tenderSearchSlice";
import {FADE_IN} from "../../../../consts/FramerMotionVariants";
import {motion} from "framer-motion";



export const CompanyFilterButtons: React.FC< {vatNumberFilters: string[]}> = ({
                                                                   vatNumberFilters
                                                               }) => {
    const dispatch = useAppDispatch();
    let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
    const followedCompanies = RaiseUser().user?.companyPageInteractions ?? [];
    const followedCompaniesSelected: CompanyPageInteraction[] = followedCompanies.filter(c => vatNumberFilters.includes(c.companyPageVatNumber));
    const removeCompanyFilter = (company: CompanyPageInteraction) => {
        dispatch(updateSearchFilters({vatNumberFilters: vatNumberFilters.filter(v => v !== company.companyPageVatNumber), userInteractions:userInteractions}));
    }
    if(vatNumberFilters == null || followedCompaniesSelected == null || followedCompaniesSelected.length === 0) return <></>
    return <>
        {followedCompaniesSelected.map((c, i) => <CompanyFilterButton key={i} company={c} removeCompanyFilter={removeCompanyFilter}/>)}
    </>
}

export const CompanyFilterButton: React.FC<{company: CompanyPageInteraction, removeCompanyFilter: (company: CompanyPageInteraction) => void}> = ({company, removeCompanyFilter}) => {
    const {t} = useTranslation();
    return <>
        <motion.button variants={FADE_IN}>
            <span>{company.companyPageName}</span>
            <span onClick={() => removeCompanyFilter(company)} style={{cursor:"pointer"}}><CloseIcon/></span>
        </motion.button>
    </>
}