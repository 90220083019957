import React, { useEffect, useState } from "react";
import "./subjectCodes.scss";
import { useTranslation } from "react-i18next";
import { SubjectCodes } from "../../../consts/subjectCodes";
import { CodeBranch, CodeType } from "../../../types/code";
import moment from "moment/moment";
import { MinusIcon, PlusIcon } from "../../../components/icons";
import { DebounceTextInput } from "../../../components/debounceInput/DebounceTextInput";
import { CodeTreeUtil, CodeUtil, CpvUtil } from "../../../utils/codes";
import { SaveButton } from "../../../components/saveButton/SaveButton";
import { SavedSearchDetailsTabProps } from "../index";

export const SubjectCodePage: React.FC<SavedSearchDetailsTabProps> = ({
  userUuid,
  isLoading,
  savedSearch,
  updateSavedSearch,
  setUnsavedChanges,
}) => {
  const allSubjectCodes: string[] = SubjectCodes.map((c) => CpvUtil.getCpvFullCode(c));
  const [selectedCodes, setSelectedCodes] = useState<CodeBranch[]>(
    savedSearch.subjectCodes !== undefined ? savedSearch.subjectCodes : []
  );
  const isSelected = (cpv: string): boolean => {
    return CodeTreeUtil.isSelected(cpv, selectedCodes, CodeType.CPV);
  };
  const addCodeWithAncestors = (cpv: string) => {
    setSelectedCodes(CodeTreeUtil.addCodeWithDescendants(cpv, selectedCodes, CodeType.CPV));
  };
  const removeCodeWithAncestors = (cpv: string) => {
    setSelectedCodes(CodeTreeUtil.removeCodeWithDescendants(cpv, selectedCodes, allSubjectCodes, CodeType.CPV));
  };
  const toggleCode = (cpv: string): void => {
    if (isSelected(cpv)) removeCodeWithAncestors(cpv);
    else addCodeWithAncestors(cpv);
    setUnsavedChanges(true);
  };
  const hasSelectedDescendant = (cpv: string): boolean => {
    return CodeTreeUtil.hasSelectedDescendant(cpv, selectedCodes, CodeType.CPV);
  };
  const isInclusive = (cpv: string): boolean => {
    return CodeTreeUtil.isInclusive(cpv, selectedCodes, CodeType.CPV);
  };
  const updateSubjectCodes = ():boolean => {
    if (savedSearch.uuid) {
      //inform nighteyes
      updateSavedSearch({
        userUuid: userUuid,
        uuid: savedSearch.uuid,
        name: savedSearch.name,
        subjectCodes: selectedCodes,
      });
      //update state and remove opportunities from store
      setUnsavedChanges(false);
      return true;
    } else return false;
  };
  return (
    <>
      {/*{isLoading && <Loader/>}*/}
      <div>
        <div className="cpv-all-content-alignment">
          <div className="radio-cpv-alignment">
            <SubjectCodesTree
              allSubjectCodes={allSubjectCodes}
              toggleCode={toggleCode}
              isSelected={isSelected}
              hasSelectedDescendant={hasSelectedDescendant}
              isInclusive={isInclusive}
              savedSearchUuid={savedSearch.uuid ? savedSearch.uuid : "new_saved_search"}
            />
          </div>
        </div>
      </div>
      <div className={"fill-button"}>
        <SaveButton saving={isLoading} save={updateSubjectCodes} />
      </div>
    </>
  );
};

export interface SubjectCodeTreeProps {
    allSubjectCodes: string[];
    isSelected: (cpv: string) => boolean;
    toggleCode: (cpv: string) => void;
    hasSelectedDescendant: (cpv:string) => boolean;
    isInclusive: (cpv:string) => boolean;
    savedSearchUuid: string;
}

export const SubjectCodesTree: React.FC<SubjectCodeTreeProps> = ({ allSubjectCodes, isSelected, toggleCode, hasSelectedDescendant, isInclusive, savedSearchUuid}) => {
    const {t} = useTranslation();
    const [subjectCodesToShow, setSubjectCodesToShow] = useState(allSubjectCodes);
    const [showAll, setShowAll] = useState(false);
    const searchSubjectCodes = (query: string) => {
        if(query === "") resetSubjectCodes();
        else {
            let codesFound: string[] = CodeUtil.searchCodesAndReturnWithAncestors(query, allSubjectCodes, CodeType.CPV,100, t);
            setSubjectCodesToShow(codesFound);
        }
    };
    const resetSubjectCodes = () => {
        setShowAll(false);
        setSubjectCodesToShow(allSubjectCodes);
    };

    let rootSubjectCodes: string[] = CodeUtil.findCpvCodesByLevel(0, subjectCodesToShow).map(c => CpvUtil.getCpvFullCode(c));
    //this prevents a render issue with code children, sometimes causing them not to update on a second search (eg if root 72 is in both queries)

    useEffect(() => {
        if (subjectCodesToShow.length < 1000){
            setShowAll(true); //problem: showall re-renders all 9000+ Cpv components
        }
    }, [subjectCodesToShow])
    return <>
        <DebounceTextInput search={searchSubjectCodes} delay={300} placeHolder={t("settings.search")}/>
        <div className="all-content-cpv-list-alignment">
            {rootSubjectCodes.length === 0 && <div className='no-results-text'>No results</div>}
            {rootSubjectCodes.map((cpv, i) =>
                <Cpv key={cpv +"-" + savedSearchUuid}
                     code={cpv}
                     toggleCode={toggleCode}
                     isSelected={isSelected}
                     hasSelectedDescendant={hasSelectedDescendant}
                     isInclusive={isInclusive}
                     availableSubjectCodes={subjectCodesToShow}
                     showAllDescendants={showAll}
                     savedSearchUuid={savedSearchUuid}
                />)}
        </div>
    </>
}

interface CpvProps{
    code: string;
    toggleCode: (cpv: string) => void;
    isSelected: (cpv: string) => boolean;
    hasSelectedDescendant: (cpv: string) => boolean;
    isInclusive: (cpv: string) => boolean;
    availableSubjectCodes: string[];
    showAllDescendants: boolean;
    savedSearchUuid: string;
}

const Cpv: React.FC<CpvProps> = ({code, toggleCode, isSelected, hasSelectedDescendant, availableSubjectCodes, showAllDescendants, isInclusive, savedSearchUuid}) => {

    const {t} = useTranslation();
    const isChecked = isSelected(code);
    const hasChildren = CodeUtil.hasChildren(code, availableSubjectCodes, CodeType.CPV);
    // const [isOpen, setIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(showAllDescendants || (!isChecked && hasSelectedDescendant(code)) || (isChecked && !isInclusive(code)));
    const [children, setChildren] = useState<string[]>(isOpen ? CodeUtil.findChildrenFromBranch(code, availableSubjectCodes, CodeType.CPV) : [])
    const showChildren = () => {setChildren(CodeUtil.findChildrenFromBranch(code, availableSubjectCodes, CodeType.CPV)); setIsOpen(true);}
    const hideChildren = () => {setChildren([]); setIsOpen(false);}
    const toggleChildrenVisbility = () => {
        if(!isOpen) showChildren();
        else hideChildren();
    }
    const toggleCodeAndChildren =() => {
        toggleCode(code);
        hideChildren();
    }
    let level = CpvUtil.getCpvLevel(code);
    let className = level === 0 ? "first-layout-alignment" : "sec-content-alignment sec-content-alignment-left-" + (level)
    return <>
        <div >
            <div className={className}>
                {hasChildren && isOpen && <div className={'center-align'} onClick={toggleChildrenVisbility} data-cy={"hide-children-button"}><MinusIcon/></div>}
                {hasChildren && !isOpen && <div className={'center-align'} onClick={toggleChildrenVisbility} data-cy={"show-children-button"}><PlusIcon/></div>}
                {!hasChildren && <div className={'center-align'}><MinusIcon/></div>}
                <div>
                    <div className='child-product'>
                        <input onClick={toggleCodeAndChildren} defaultChecked={isChecked} type="checkbox" id={code} data-cy={"input-checkbox"}/>
                        <label htmlFor={code} ><span>{CodeUtil.getCodeWithTranslation({code: code, type: CodeType.CPV}, t)}</span></label>
                    </div>
                </div>
            </div>
            {children.map((cpv) =>
                <Cpv key={cpv + "-" + savedSearchUuid}
                     code={cpv}
                     isSelected={isSelected}
                     toggleCode={toggleCode}
                     hasSelectedDescendant={hasSelectedDescendant}
                     isInclusive={isInclusive}
                     availableSubjectCodes={availableSubjectCodes}
                     showAllDescendants={showAllDescendants}
                     savedSearchUuid={savedSearchUuid}
                />)}
        </div>
    </>
}